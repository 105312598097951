.ffm {
  font-family: "montserrat", sans-serif;
}
body {
  background: #f5f5f5;
}
.ant-dropdown {
  position: fixed;
}
.overLoayQr {
  position: relative;
}

.overLoayQr::after {
  content: "Connect to start chat";
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(0, 0, 0);
  font-size: 20px;
  font-weight: 800;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(225 242 245 / 88%);
  border-radius: 10px;
}
.overLayAl {
  position: relative;
}
.overLayAl::after {
  content: "Set Alias to start";
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(0, 0, 0);
  font-size: 20px;
  font-weight: 800;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(225 242 245 / 88%);
  border-radius: 10px;
}
.overLayChat {
  position: relative;
}
.overLayChat::after {
  content: "Set alias to start";
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(0, 0, 0);
  font-size: 25px;
  font-weight: 800;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(225 242 245 / 88%);
  border-radius: 10px;
}
.attendeeSmcont {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid #ebe2e2;
  box-shadow: 2px 3px 5px -1px #151515;
  margin-bottom: 15px;
  border-radius: 10px;
}
.hyperlink {
  color: #267c93;
}
.orgStrip {
  word-break: break-word;
  background: #267c93;
  text-align: center;
  color: white;
  text-transform: uppercase;
  writing-mode: tb;
  letter-spacing: 4px;
  border-radius: 5px 0px 0px 5px;
  cursor: pointer;
  transform: rotate(180deg);
}

.ev-Card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.ev-Card-status {
  border-radius: 0px 0px 5px 5px;
}

.dt-card {
  border: 1px solid #929292;
}
.h-screen {
  height: 90vh !important;
}
:where(.css-dev-only-do-not-override-10ed4xt).ant-tabs
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #267c93 !important;
  text-shadow: 0 0 0.25px currentcolor;
}
:where(.css-dev-only-do-not-override-10ed4xt).ant-tabs .ant-tabs-ink-bar {
  position: absolute;
  background: #267c93 !important;
  pointer-events: none;
}
:where(.css-dev-only-do-not-override-10ed4xt).ant-tabs-large
  > .ant-tabs-nav
  .ant-tabs-tab {
  padding: 16px 0;
  font-size: 20px !important;
}

.landingModal {
  background: white;
  padding: 20px;
  border-radius: 5px;
}
